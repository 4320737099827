import React from 'react'

function SignUp() {
   return (
      <>
         <h1 
            className='sign-up'
            style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/img-8.jpg'})` }}
         >
            SIGN UP
         </h1>
      </>
   )
}

export default SignUp
